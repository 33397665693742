export class Msg {
    constructor() {
        this.id = "MSG_" + 9876 + Math.floor(1000000000000000 * Math.random());
        let dateObject = new Date();
        this.date = dateObject.toLocaleTimeString().substr(0, 5);
        this.initSession = Number(sessionStorage.getItem('init_session'));
    }
}
export class UserMsg extends Msg {
    constructor() {
        super();
        this.type = "USER_MSG";
    }
}
export class BotMsg extends Msg {
    constructor(type) {
        super();
        this.answered = false;
        this.type = type;
    }
}
export class GraphMsg extends BotMsg {
    constructor(type) {
        super(type);
    }
}
export class CardCuourseMsg extends BotMsg {
    constructor(type) {
        super(type);
    }
}
export class DfObj {
    constructor(sessionId, language, path, initSession) {
        var _a, _b, _c, _d;
        this.sessionId = sessionId;
        const cookieGA4 = GA_SESSION_ID[window.location.hostname]
            ? GA_SESSION_ID[window.location.hostname]
            : window.location.hostname.includes('orientador.prefundacion.com')
                ? "_ga_XRXDZXD61K"
                : null;
        this.realSessionId = cookieGA4 ? (_d = (_c = (_b = (_a = document.cookie.split(';')) === null || _a === void 0 ? void 0 : _a.filter(c => c.trim().startsWith(cookieGA4))) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.match(/\.\d+\.(\d+)\./)) === null || _d === void 0 ? void 0 : _d[1] : sessionStorage.getItem('realSessionId');
        this.language = language;
        this.path = path;
        this.initSession = Number(sessionStorage.getItem('init_session'));
        this.origin = window.location.origin + window.location.pathname;
    }
}
export class DfMsg extends DfObj {
    constructor(message, sessionId, language, initSession) {
        var _a, _b, _c, _d;
        super(sessionId, language, 'message');
        this.message = message.replace(/[`~@#$%^&*()_|+\-=;:'",.<>\{\}\[\]\\\/]/gi, ' ');
        this.initSession = Number(sessionStorage.getItem('init_session'));
        const cookieGA4 = GA_SESSION_ID[window.location.hostname]
            ? GA_SESSION_ID[window.location.hostname]
            : window.location.hostname.includes('orientador.prefundacion.com')
                ? "_ga_XRXDZXD61K"
                : null;
        this.origin = window.location.origin + window.location.pathname;
        this.realSessionId = cookieGA4 ? (_d = (_c = (_b = (_a = document.cookie.split(';')) === null || _a === void 0 ? void 0 : _a.filter(c => c.trim().startsWith(cookieGA4))) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.match(/\.\d+\.(\d+)\./)) === null || _d === void 0 ? void 0 : _d[1] : sessionStorage.getItem('realSessionId');
    }
}
export class DfEvent extends DfObj {
    constructor(event, sessionId, language, parameters) {
        var _a, _b, _c, _d;
        super(sessionId, language, 'event');
        this.event = event;
        this.parameters = parameters;
        const cookieGA4 = GA_SESSION_ID[window.location.hostname]
            ? GA_SESSION_ID[window.location.hostname]
            : window.location.hostname.includes('orientador.prefundacion.com')
                ? "_ga_XRXDZXD61K"
                : null;
        this.origin = window.location.origin + window.location.pathname;
        this.realSessionId = cookieGA4 ? (_d = (_c = (_b = (_a = document.cookie.split(';')) === null || _a === void 0 ? void 0 : _a.filter(c => c.trim().startsWith(cookieGA4))) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.match(/\.\d+\.(\d+)\./)) === null || _d === void 0 ? void 0 : _d[1] : sessionStorage.getItem('realSessionId');
    }
}
export class RatingMsg extends BotMsg {
    constructor(type) {
        super(type);
    }
}
