import { DfEvent } from "./Messages";
import { Responses } from "./Responses";
import { ErrorFlowMsg } from "./ErrorMessages";
export class BrokerConnection {
    constructor(chatControl) {
        this.chatControl = chatControl;
        this.responses = new Responses(this.chatControl);
        this.url = HOST_URL + '/df/send/';
        this.getUriParams = () => {
            const url_string = window.location.href;
            const url = new URL(url_string);
            this.chatControl.utm = this.getJsonFromUrl(url.search);
            return url.search;
        };
        this.getToken = (callback) => {
            this.chatControl.iduser = '';
            const uri = `${HOST_URL}/mw/getToken`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        this.chatControl.token = JSON.parse(req.response).data.token;
                        callback();
                    }
                    else {
                        this.chatControl.errorModal();
                    }
                }
            };
            req.send();
        };
        this.getCorsWhitelist = (windowLocation, callback) => {
            const uri = `${HOST_URL}/mw/api/allowedlocation?windowLocation=${windowLocation}`;
            const req = new XMLHttpRequest();
            let isWhitelisted = false;
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatControl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    console.log(JSON.parse(req.response).data);
                    if (req.status == 200) {
                        callback(null, true);
                    }
                    else if (req.status == 210) {
                        callback(null, false);
                    }
                    else {
                        callback('error');
                    }
                }
            };
            req.send();
        };
        this.language = chatControl.language;
        this.channel = chatControl.channel;
        this.initSession = Number(sessionStorage.getItem('init_session'));
    }
    init(callback) {
        var _a, _b, _c, _d;
        this.checkSessionId();
        this.chatControl.sessionId = this.sessionId;
        console.log('---------------');
        const cookieGA4 = GA_SESSION_ID[window.location.hostname]
            ? GA_SESSION_ID[window.location.hostname]
            : window.location.hostname.includes('orientador.prefundacion.com')
                ? "_ga_XRXDZXD61K"
                : null;
        this.realSessionId = cookieGA4 ? (_d = (_c = (_b = (_a = document.cookie.split(';')) === null || _a === void 0 ? void 0 : _a.filter(c => c.trim().startsWith(cookieGA4))) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.match(/\.\d+\.(\d+)\./)) === null || _d === void 0 ? void 0 : _d[1] : sessionStorage.getItem('realSessionId');
        this.origin = this.chatControl.originHost;
        const initialMsg = new DfEvent('welcome', this.sessionId, this.language, { origin: this.chatControl.originHost });
        this.send(initialMsg, callback);
        this.chatControl.gaConnection.sendGAInicio();
    }
    ;
    checkSessionId() {
        if (this.chatControl.acceptedCookies()) {
            this.sessionId = document.cookie.split('; ').map(element => element.split('=')).find(element => element[0] === "_ga") ?
                document.cookie.split('; ').map(element => element.split('=')).filter(element => element[0] === "_ga").map(element => element[1]).sort()[0].split('.').slice(2, 4).join('.') :
                this.chatControl.sessionId !== 'undefined' ? this.chatControl.sessionId : '000' + Math.floor(100000000000000000 * Math.random());
            this.chatControl.sessionId = this.sessionId;
        }
        else {
            this.chatControl.sessionId = "undefined";
        }
    }
    sendCustomEvent(event, params, callback) {
        const bodyparams = params ? Object.assign(Object.assign({}, params), { origin: this.chatControl.originHost }) : { origin: this.chatControl.originHost };
        const msg = new DfEvent(event, this.sessionId, this.language, bodyparams);
        this.send(msg, callback);
    }
    ;
    getJsonFromUrl(url) {
        if (!url)
            url = location.search;
        const query = url.substr(1);
        const result = {};
        query.split("&").forEach(function (part) {
            const item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }
    send(msg, callback) {
        const params = this.getUriParams();
        const url = this.url + msg.path + params;
        this.sendMsg(msg, url, (err, response) => {
            if (err) {
                console.log('error');
                new ErrorFlowMsg(this.chatControl, callback);
            }
            else {
                this.responses.setResponse(response, callback);
            }
        });
    }
    ;
    sendMsg(msg, uri, callback) {
        msg.channel = this.channel;
        const req = new XMLHttpRequest();
        req.open("POST", uri, true);
        req.setRequestHeader('Content-Type', 'application/json');
        req.onreadystatechange = () => {
            if (req.readyState == 4) {
                if (req.status == 200) {
                    if (req.response) {
                        const response = JSON.parse(req.response);
                        return callback(null, response);
                    }
                    else {
                        console.log('empty response, repating call');
                    }
                }
                else {
                    console.log("Conection Error: ", req);
                    return callback(req);
                }
            }
        };
        req.send(JSON.stringify(msg));
    }
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2)
            return parts.pop().split(';').shift();
        return undefined;
    }
    setCookie(cName, cValue, expDays = 365) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }
    checkCookie(c_name) {
        const c = this.getCookie(c_name);
        return (c != undefined && c == '1');
    }
    checkConsent() {
        return this.checkCookie(COOKIE_NAME);
    }
}
