export class ButtonsControl {
    constructor() {
        $("body").on('DOMSubtreeModified', '#chatbotContainer', () => {
            $('.ftcb__msg__btn-content').click(function () {
                $(this).addClass("ftcb__msg__btn-clicked");
                $(this).on("transitionend webkitTransitionEnd oTransitionEnd", () => {
                    $(this).removeClass("ftcb__msg__btn-clicked");
                });
            });
        });
    }
}
