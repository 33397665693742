const config = require("./../../config.js");
export class GAConnection {
    constructor(chatControl) {
        this.chatControl = chatControl;
    }
    sendGAEvent(category, action, label, index, nonIntraction) {
        const uri = HOST_URL + '/ga/event';
        const data = {
            userId: this.chatControl.iduser,
            clientId: this.chatControl.sessionId,
            realSessionId: this.chatControl.realSessionId,
            queryString: this.chatControl.utm,
            codigoUA: config.codigoUA,
            origin: this.chatControl.originHost,
            express: this.chatControl.isExpressUser,
            intent: this.chatControl.currentIntent,
            index: index,
            label: label,
            action: action,
            category: category,
            nonIntraction: Boolean,
            channel: this.chatControl.channel,
            initSession: Number(sessionStorage.getItem('init_session'))
        };
        const req = new XMLHttpRequest();
        req.open("POST", uri, true);
        req.setRequestHeader('Content-Type', 'application/json');
        req.onreadystatechange = () => {
            if (req.readyState == 4) {
            }
        };
        req.send(JSON.stringify(data));
    }
    sendGAInicio() {
        const uri = HOST_URL + '/ga/init';
        const data = {
            userId: this.chatControl.iduser,
            clientId: this.chatControl.sessionId,
            realSessionId: this.chatControl.realSessionId,
            queryString: this.chatControl.utm,
            codigoUA: config.codigoUA,
            origin: this.chatControl.originHost,
            channel: this.chatControl.channel,
            initSession: Number(sessionStorage.getItem('init_session'))
        };
        const req = new XMLHttpRequest();
        req.open("POST", uri, true);
        req.setRequestHeader('Content-Type', 'application/json');
        req.onreadystatechange = () => {
            if (req.readyState == 4) {
            }
        };
        req.send(JSON.stringify(data));
    }
}
